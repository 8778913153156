let App = App || {};

App.parseErrors = function(errors) {
    if (errors) {
        var result = "<ul>";
        $.each(errors, function(index, item) {
            $.each(item, function(index, value) {
                result += "<li>" + value + "</li>";
            });
        });
        result += "</ul>";
        return result;
    } else {
        return "<p>" + window.language.error_description + "</p>";
    }
};


$(function () {
    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="_token"]').attr('content')
        },
        dataType: "JSON"
    });

    $('.checkout-form').on('submit', function(e) {
        e.preventDefault();

        var $form = $(this),
            formData = new FormData($form[0]),
            $button = $form.find('button[type=submit]');

        $button.attr('disabled', true).addClass('btn--loading');

        $.ajax({
            type: 'POST',
            url: '/request',
            data: formData,
            cache: false,
            contentType: false,
            processData: false,
            success(resp) {
                $button.attr('disabled', false).removeClass('btn--loading');
                $form[0].reset();
                swal(window.language.thanks, window.language.thanks_text, "success");
                if (typeof yaCounter39005455 != 'undefined') {
                    yaCounter39005455.reachGoal('REQUEST');
                }
                if (typeof ga != 'undefined') {
                    ga('send', 'event', 'request', 'send');
                }
            },
            error(data) {
                $button.attr('disabled', false).removeClass('btn--loading');
                let errors = data.responseJSON;

                swal({
                    title: window.language.error,
                    text: !errors ? window.language.error_description : App.parseErrors(errors),
                    html: true,
                    type: "error"
                });
            }
        });
    });

    $(".link_anchor").on('click', function (e) {
        event.preventDefault();
        var selected = $(this).attr('href'),
            top = $(selected).offset().top;
        $('body,html').animate({scrollTop: top}, 600);
    });

    $(".btn-close").on('click', function (e) {
         // e.preventDefault для mozzilla
         if(e.preventDefault){ 
                e.preventDefault()
            }
        else{
            e.stop()
            }; 
        $('.address-block,.btn-close').fadeOut(200);
    });

    var map;

    function initMap() {
        var myLatLng = {lat: 50.388385, lng: 30.361532};

        map = new google.maps.Map(document.getElementById('map'), {
            center: myLatLng,
            zoom: 16,
            scrollwheel: false
        });
        var marker = new google.maps.Marker({
            position: myLatLng,
            map: map
        });
        marker.addListener('click', function () {
            $('.address-block,.btn-close').fadeIn(200);
        });
    }

    initMap();

});
